<template>
    <div id="login-component">
        <v-card-title class="justify-center text-center">
            <v-img :src="$store.getters['display_option/logo']" alt="Logo" class="mb-4" contain max-height="70" width="100%" />

            <strong>{{ $t('login_account_component.welcome') }}</strong>
        </v-card-title>

        <v-card-text class="pb-0">
            <v-text-field v-model="email" :label="$t('login_account_component.email')" :rules="email_rules" data-cy="login-email" filled />

            <v-text-field
                v-model="password"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :hide-details="'auto'"
                :label="$t('login_account_component.password')"
                :rules="[(v) => !!v || this.$t('login_account_component.password_required')]"
                :type="show_password ? 'text' : 'password'"
                data-cy="login-password"
                filled
                @click:append="show_password = !show_password"
                @keypress.enter="form_valid ? login() : ''"
            />

            <span class="font-12 text-decoration-underline primary--text pointer" @click="$emit('showPasswordReset')">
                {{ $t('login_account_component.forget_password') }}
            </span>

            <v-checkbox v-model="remember_me">
                <template v-slot:label>
                    <span class="font-12">{{ $t('login_account_component.stay_connected') }}</span>
                </template>
            </v-checkbox>
        </v-card-text>

        <v-card-actions class="ml-2 mr-2">
            <v-btn
                :disabled="!form_valid"
                :loading="loading_login"
                block
                color="primary font-weight-bold btn-secondary-loader"
                large
                @click="login()"
                data-cy="login-btn"
            >
                <span :class="form_valid ? 'secondary--text font-weight-bold' : 'font-weight-bold'">
                    {{ $t('login_account_component.login') }}
                </span>
            </v-btn>
        </v-card-actions>
        <p v-if="is_mobile" class="text-center display-version version-login-mobile">Version {{ app_version }}</p>
    </div>
</template>

<script>
export default {
    name: 'LoginAccountComponent',
    data: function () {
        return {
            email: '',
            password: '',
            remember_me: false,
            show_password: false,
            email_structure: /^\S+@\S+\.\S+$/,
            email_rules: [
                (v) => !!v || this.$t('login_account_component.email_required'),
                (v) => this.email_structure.test(v.replace(/\s+/g, '')) || this.$t('login_account_component.email_not_valid'),
            ],
            loading_login: false,
        }
    },
    computed: {
        form_valid() {
            return this.email !== '' && this.email_structure.test(this.email.replace(/\s+/g, '')) && this.password !== ''
        },
    },
    methods: {
        login() {
            this.loading_login = true
            this.$auth
                .login({
                    data: {
                        email: this.email.replace(/\s+/g, ''),
                        password: this.password,
                    },
                    staySignedIn: this.remember_me,
                })
                .then((success) => {
                    this.setAllInformationAfterConnect(success.data.data.id_professional, success.data.data)
                })
                .catch((error) => {
                    if (error.response.data.translation_key === 'wrong_page_login') {
                        this.showSnackbar(
                            'error',
                            this.$t('error_display.wrong_page_login') + ' :',
                            'https://' + this.$store.getters['display_option/display_option'].authentication_url
                        ) //'https://auth.fourdata.io/login')
                    } else {
                        this.manageError(error)
                    }
                })
                .finally(() => {
                    this.loading_login = false
                    this.$store.dispatch('general/setGlobalLoader', false)
                })
        },
    },
}
</script>

<style>
.version-login-mobile {
    position: relative;
    top: 30px;
}
</style>
