<template>
    <div id="new-password-component">
        <v-card-title class="justify-center text-center">
            <v-img :src="$store.getters['display_option/logo']" alt="Logo" class="mb-4" contain max-height="70" width="100%" />

            <strong>
                {{ password_is_reset ? $t('global.congratulation') : $t('new_password_component.new_password') }}
            </strong>
        </v-card-title>

        <v-card-text class="pb-0 text-center">
            <p>
                {{ password_is_reset ? $t('new_password_component.password_update') : $t('new_password_component.enter_new_password') }}
            </p>

            <template v-if="!password_is_reset">
                <v-text-field
                    v-model="password"
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('new_password_component.password')"
                    :rules="password_rules"
                    :type="show_password ? 'text' : 'password'"
                    filled
                    @click:append="show_password = !show_password"
                />

                <v-text-field
                    v-model="confirm_password"
                    :append-icon="show_confirm_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('new_password_component.confirm_password')"
                    :rules="password_confirm_rules"
                    :type="show_confirm_password ? 'text' : 'password'"
                    filled
                    @click:append="show_confirm_password = !show_confirm_password"
                    @keypress.enter="resetPassword()"
                />
            </template>
        </v-card-text>

        <v-card-actions class="pl-4 pr-4">
            <v-btn
                :disabled="password_is_reset ? false : !form_valid"
                block
                color="primary secondary--text font-weight-bold"
                large
                @click="password_is_reset ? $emit('showLoginComponent') : resetPassword()"
            >
                {{ password_is_reset ? $t('new_password_component.login') : $t('global.validate') }}
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import UserRepository from '@/repositories/UserRepository'

export default {
    name: 'NewPasswordComponent',
    data: function () {
        return {
            password: '',
            confirm_password: '',
            password_is_reset: false,
            password_structure: /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{12,}$/,
            show_password: false,
            password_rules: [
                (v) => !!v || this.$t('new_password_component.password_required'),
                (v) => this.password_structure.test(v) || this.$t('new_password_component.password_rule_creation'),
            ],
            show_confirm_password: false,
            password_confirm_rules: [
                (v) => !!v || this.$t('new_password_component.confirm_password_required'),
                (v) => this.password === v || this.$t('new_password_component.password_do_not_match'),
            ],
        }
    },
    props: {
        uid: {
            type: String,
            required: true,
        },
        code: {
            type: String,
            required: true,
        },
    },
    computed: {
        form_valid() {
            return this.password_structure.test(this.password) && this.password === this.confirm_password
        },
    },
    created() {
        this.checkIfUserCanResetPassword()
    },
    methods: {
        checkIfUserCanResetPassword() {
            UserRepository.postCheckIfUserCanResetPassword({
                uid: this.uid,
                code: this.code,
            }).catch((error) => {
                this.manageErrorApi(error, 'error', this.$t('new_password_component.password_reset_url_error'))
            })
        },

        resetPassword() {
            UserRepository.postResetPassword({
                uid: this.uid,
                code: this.code,
                password: this.password,
                confirm_password: this.confirm_password
            })
                .then(() => {
                    this.password_is_reset = true
                })
                .catch((error) => {
                    this.manageErrorApi(error, 'error', this.$t('new_password_component.password_reset_error'))
                })
        },

        manageErrorApi(error, type, message) {
            if (error.response && error.response.status === 404) {
                message = this.$t('new_password_component.password_reset_not_found')
            } else if (error.response && error.response.status === 403) {
                message = this.$t('new_password_component.password_reset_already_use')
            }

            this.$store.dispatch('snackbar/showSnackbar', { snackbar_type: 'error', snackbar_text: message })
            this.$emit('showLoginComponent')
        },
    },
}
</script>

<style scoped></style>
